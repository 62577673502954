function UserData(props) {
    const userData = props.userData || {}
    return (
        <div>
            <p>Username: {userData.name}</p>
            <p>User ID: {userData.id}</p> 
            <p>Verified: {userData.hasVerifiedBadge ? "true" : "false"}</p>
            <p>User Place Visits: {props.userVisits}</p>
            <p>Group Members: {props.members}</p>
            <p>Group Place Visits: {props.groupVisits}</p>
        </div>
    )
}


export default UserData;