function Place(props) {
    const place = props.place
    const group = props.group
    return (
        <div>
            <p><a href={`https://www.roblox.com/games/${place.rootPlace.id}`} target="_blank"><b>{place.name}</b></a></p>
            <p>Visits: {place.placeVisits}</p>
            {group !== undefined ? <p>Group: <a href={`https://www.roblox.com/groups/${group.group.id}`}>{group.group.name}</a></p>: <></>}
            {group !== undefined ? <p>Role: {props.group.role.name}</p> : <></>}
            {group !== undefined ? <p>Rank: {props.group.role.rank}</p> : <></>}
        </div>
    )
}


export default Place;