function Group(props) {
    const group = props.group
    return (
        <div>
            <p><a href={`https://www.roblox.com/groups/${group.group.id}`} target="_blank"><b>{group.group.name}</b></a></p>
            <p>Members: {group.group.memberCount}</p>
            <p>Role: {group.role.name}</p>
            <p>Rank: {group.role.rank}</p>
        </div>
    )
}


export default Group;