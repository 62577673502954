import './App.css';
import { useState } from 'react';
import axios from 'axios'

import UserData from './components/UserData';
import UserPlaceData from './components/UserPlaceData';
import GroupData from './components/GroupData';
import GroupPlaceData from './components/GroupPlaceData';

function App() {
  const [username, setUsername] = useState('');
  const [data, setData] = useState({});
  const [showButton, setShowButton] = useState(true);

  function changeUsername(e) {
    setUsername(e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()
    setShowButton(false);
    if (username === "") return;
    axios.get(`https://devhub-g2ma.onrender.com/${username}`)
    .then(function(response) {
      setData(response.data)
      setShowButton(true);
    })
    .catch(function(error) {
      console.log(error)
    })
  }

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder='Username' value={username} onChange={changeUsername}/>
        {
          showButton ? <input type="submit" value="Submit"/> : <>Loading</>
        }
      </form>
      <hr />
      <UserData userData={data.UserData} 
      userVisits={
        data.UserPlaceData !== undefined ? data.UserPlaceData.Total : 0
      } 
      members={
        data.GroupData !== undefined ? data.GroupData.Members : 0
      }
      groupVisits={
        data.GroupPlaceData !== undefined ? data.GroupPlaceData.Total : 0
      } />
      <hr />
      <UserPlaceData userPlaceData={data.UserPlaceData} />
      <hr />
      <GroupData groupData={data.GroupData} />
      <hr />
      <GroupPlaceData groupPlaceData={data.GroupPlaceData} groupData={data.GroupData} />
      <hr />
      <p>Website made by <a href="https://www.roblox.com/users/1936118388/profile">xendatro</a>. Requests sometimes take up to a minute. If inaccurate data shows up, just try again.</p>
    </div>
  );
}

export default App;
