import Place from "./Place";

function UserPlaceData(props) {
    const userPlaceData = props.userPlaceData
    if (userPlaceData === undefined) return; 
    const data = userPlaceData.Games.map((place) => <Place place={place} key={place.id} />)
    return (
        <div>
            <h4>User Place Data:</h4>
            {data}
        </div>
    )
}


export default UserPlaceData;