import Group from "./Group";

function GroupData(props) {
    let groupData = props.groupData
    if (groupData === undefined) return; 
    groupData = groupData.Groups
    const data = groupData.Groups.map((group) => <Group group={group} key={group.group.id} />)
    return (
        <div>
            <h4>Group Data:</h4>
            {data}
        </div>
    )
}


export default GroupData;