import Place from "./Place";

function GroupPlaceData(props) {
    const groupPlaceData = props.groupPlaceData
    const groupData = props.groupData
    if (groupPlaceData === undefined) return; 
    const data = groupPlaceData.Games.map((place) => {
        return <Place place={place} key={place.id} group={groupData.Groups.Groups.find((group) => group.group.id === place.creator.id)} />
    })
    return (
        <div>
            <h4>Group Place Data:</h4>
            {data}
        </div>
    )
}


export default GroupPlaceData;